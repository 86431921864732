<template>
  <div>
    <div style="background:#010b30">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1378px; margin:0 auto; text-align:left">
        <ul>
          <li class="tabStyleLi">
            <div class="tabStyle" style="background:#3246F4"></div>
            <div
              class="tabStyle2"
              style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
            >
              <div>01</div>
              <div>{{ $t('Nav.qiyegaishu') }}</div>
            </div>
          </li>

          <router-link :to="{ path: '/components/web/aboutus/qyyj' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.qiyeyuanjing') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/aboutus/zyzz' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.zhuanyezizhi') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/joinus/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>{{ $t('Nav.qiyewenhua') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">
            {{ $t('Nav.home') }}</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/aboutus/index' }"
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.qiyegaishu')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto;">
      <div
        style="margin-top:50px;margin-bottom:30px; font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
      >
        {{ $t('Nav.qiyegaishu') }}
      </div>

      <div
        style="text-indent:30px;text-align:left; line-height: 27px;margin-top:30px;"
      >
        {{ $t('Nav.qiyegaishuText04') }}
      </div>
      <div style="text-indent:30px;text-align:left; line-height: 27px;">
        {{ $t('Nav.qiyegaishuText05') }}
      </div>

      <div style="text-indent:30px;text-align:left; line-height: 27px;">
        {{ $t('Nav.qiyegaishuText06') }}
      </div>
      <div
        style="text-indent:30px;text-align:left; line-height: 27px;margin-bottom:30px;"
      >
        {{ $t('Nav.qiyegaishuText07') }}
      </div>
      <el-image
        :src="corporateVision1"
        style="width:1200px; height:504px;"
        fit="contain"
      ></el-image>
    </div>
    <div style="height:20px"></div>
    <div style="width:1200px; margin:0 auto; height:200px">
      <div style="float:left; font-size:30px;">
        {{ $t('title') }}
      </div>
      <div style="folat:right;">
        <el-image
          :src="leftImg"
          style="width:64px; height:61px;float:left;margin-left:40px"
          fit="contain"
        ></el-image>
        <span style="font-size:16px;display:inline-block; margin-top:40px">
          {{ $t('Nav.qiyegaishuText08') }}</span
        >
        <el-image
          :src="rightImg"
          style="width:64px; height:61px;float: right; margin-top:40px;"
          fit="contain"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'corporateVision',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/22.jpg'),
      corporateVision1: require('../../../assets/aboutus/qygs.png'),
      leftImg: require('../../../assets/aboutus/abico1.png'),
      rightImg: require('../../../assets/aboutus/abico2.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 150px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
